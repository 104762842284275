import React, {useEffect, useMemo, useState} from "react";
import PageLayout from "@/components/pageLayout";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {getClusterGroupById} from "@/services/clusterGroup";

import {useQuery} from "@/utils";
import ClusterGroupForm from "@/pages/warning/clusterGroup/components/clusterGroupForm";

const ClusterGroupEdit: React.FC = () => {
    const { t } = useTranslation();
    const params: any = useParams();
    const query = useQuery();
    const isClone = query.get('mode');
    const [curAlertTemplate, setCurAlertTemplate] = useState<any>({});
    const id = useMemo(() => {
        return params.id;
    }, [params]);
    useEffect(() => {
        getClusterGroupDetail();
        return () => {};
    }, [id]);

    const getClusterGroupDetail = async () => {
        const res = await getClusterGroupById(id);
        setCurAlertTemplate(res.dat.data || {});
    };

    return(
        <PageLayout title={t('数据源组')} showBack backPath='/cluster-group'>
            {curAlertTemplate.id && <ClusterGroupForm detail={curAlertTemplate} type={!isClone ? 1 : 2} />}
        </PageLayout>
    );
};




export default ClusterGroupEdit;
