import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { Switch, Card, Form, Input, Row, Col, Button, message, Space, Select, InputNumber } from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { addAlertTemplate, updateAlertTemplate, listAlertTemplatePlatform, updateAlertTemplatePlatform, addAlertTemplatePlatform } from '@/services/warning';
import AdvancedWrap from '@/components/AdvancedWrap';
import CateSelect from './CateSelect';
import ClusterSelect from './ClusterSelect';
import { parseValues } from './utils';
export { ClusterAll } from './ClusterSelect';

const { TextArea } = Input;

interface Props {
  detail?: any;
  type?: number; // 1:编辑 2:克隆
}

function isValidEnVar() {
  const contentRegExp = /^[a-zA-Z][a-zA-Z0-9]*$/;
  return {
    validator(_, value) {
      const valid = value && contentRegExp.test(value);
      return valid ? Promise.resolve() : Promise.reject(new Error('变量格式不正确，请检查！'));
    },
  };
}

const operateForm: React.FC<Props> = ({ type, detail = {} }) => {
  const { t } = useTranslation();
  const history = useHistory(); // 创建的时候默认选中的值
  const [form] = Form.useForm();
  const [notifyGroups, setNotifyGroups] = useState<any[]>([]);

  useEffect(() => {
    if (type == 1) {
      const groups = (detail.notify_groups_obj ? detail.notify_groups_obj.filter((item) => !notifyGroups.find((i) => item.id === i.id)) : []).concat(notifyGroups);
      setNotifyGroups(groups);
    }
  }, [JSON.stringify(detail)]);

  const tishi = '平台变量是该平台下所有模板都需要的共用变量，比如hci里的namespace';

  const addSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        if (values.varsList != undefined && values.varsList.length > 0) {
          for (let i = 0; i < values.varsList.length; i++) {
            if (!values.varsList[i].cn || !values.varsList[i].en || !values.varsList[i].type || !values.varsList[i].default || !values.varsList[i].comment) {
              return message.error('请填写完整的平台变量');
            }
          }
        }
        if (type === 1) {
          const res = await updateAlertTemplatePlatform(values, detail.id);
          if (res.err) {
            message.error(res.error);
          } else {
            message.success(t('编辑成功！'));
            history.push('/alert-template/platform');
          }
        } else {
          const { dat } = await addAlertTemplatePlatform([values]);
          let errorNum = 0;
          const msg = Object.keys(dat).map((key) => {
            dat[key] && errorNum++;
            return dat[key];
          });

          if (!errorNum) {
            message.success(`${type === 2 ? t('克隆成功') : t('创建成功')}`);
            history.push('/alert-template/platform');
          } else {
            message.error(t(msg));
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [platforms, setPlatforms] = useState<any[]>([]);

  useEffect(() => {
    getAlertTemplatePlatform();
  }, []);

  const getAlertTemplatePlatform = async () => {
    const { success, dat } = await listAlertTemplatePlatform(0);
    if (success) {
      setPlatforms(dat || []);
    }
  };

  return (
    <div className='operate_con'>
      <Form
        form={form}
        className='strategy-form'
        layout='vertical'
        initialValues={{
          ...parseValues(detail),
        }}
      >
        <Space direction='vertical' style={{ width: '100%' }}>
          <Card title={t('基本配置')}>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  label={t('平台名称：')}
                  name='name'
                  rules={[
                    {
                      required: true,
                      message: t('名称不能为空'),
                    },
                  ]}
                >
                  <Input placeholder={t('请输入名称')} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  label={t('是否有平台级别的告警变量')}
                  name='has_var'
                  rules={[
                    {
                      required: true,
                      message: t('var不能为空'),
                    },
                  ]}
                  valuePropName='checked'
                  tooltip={tishi}
                  initialValue={false}
                >
                  <Switch />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.has_var !== curValues.has_var}>
                  {({ getFieldValue }) => {
                    if (getFieldValue('has_var')) {
                      return (
                        <>
                          <Form.Item label='平台变量' shouldUpdate initialValue={[{}]}>
                            {() => (
                              <>
                                <Form.List
                                  name='varsList'
                                  rules={[
                                    {
                                      validator: async (_, varsList) => {
                                        if (!varsList || varsList.length === 0) {
                                          return Promise.reject(new Error('变量不能为空'));
                                        }
                                      },
                                    },
                                  ]}
                                >
                                  {(fields, { add, remove }, { errors }) => (
                                    <>
                                      {fields.map(({ key, name, ...restField }) => (
                                        <Row key={key}>
                                          <Form.Item {...restField} name={[name, 'cn']} rules={[{ required: true, message: '中文名不能为空' }]}>
                                            <Input placeholder='变量中文名' style={{ width: 120, margin: 3 }} />
                                          </Form.Item>
                                          <Form.Item {...restField} name={[name, 'en']} rules={[{ required: true, message: '英文名不能为空' }, isValidEnVar]}>
                                            <Input placeholder='变量英文名' style={{ width: 120, margin: 3 }} />
                                          </Form.Item>
                                          <Form.Item {...restField} name={[name, 'type']} rules={[{ required: true, message: '类型不能为空' }]} initialValue={'text'}>
                                            <Select
                                              // defaultValue='text'
                                              // onChange={handleVarTypeChange}
                                              options={[{ value: 'text', label: '文本类型' }]}
                                              style={{ width: 120, margin: 3 }}
                                              placeholder='变量类型'
                                            />
                                          </Form.Item>
                                          <Form.Item {...restField} name={[name, 'default']} rules={[{ required: true, message: '默认值不能为空' }]}>
                                            <Input placeholder='文本变量默认值' style={{ width: 120, margin: 3 }} />
                                          </Form.Item>
                                          <Form.Item {...restField} name={[name, 'comment']} rules={[{ required: true, message: '注释说明不能为空' }]}>
                                            <TextArea allowClear rows={1} placeholder='变量注释说明' autoSize style={{ width: 240, margin: 3 }} />
                                          </Form.Item>
                                          <MinusCircleOutlined onClick={() => remove(name)} />
                                        </Row>
                                      ))}
                                      <Form.Item>
                                        <Button type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                                          新增变量
                                        </Button>
                                        <Form.ErrorList errors={errors} />
                                      </Form.Item>
                                    </>
                                  )}
                                </Form.List>
                              </>
                            )}
                          </Form.Item>
                        </>
                      );
                    }
                  }}
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <Form.Item
            style={{
              marginTop: 20,
            }}
          >
            <Button type='primary' onClick={addSubmit} style={{ marginRight: '8px' }}>
              {type === 1 ? t('提交') : type === 2 ? t('克隆') : t('创建')}
            </Button>

            <Button
              onClick={() => {
                history.push('/alert-template/platform');
              }}
            >
              {t('取消')}
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </div>
  );
};

export default operateForm;
