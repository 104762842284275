import React, {useEffect, useRef, useState} from "react";
import {Button, Card, Col, Form, Input, message, Row, Select, Space} from "antd";
import {parseValues} from "@/pages/warning/template/components/utils";
import AdvancedWrap from "@/components/AdvancedWrap";
import CateSelect from "@/pages/warning/clusterGroup/components/CateSelect";
import ClusterSelect from "@/pages/warning/clusterGroup/components/ClusterSelect";
import {useTranslation} from "react-i18next";
import {useHistory, useLocation} from "react-router-dom";
import {addAlertTemplate, updateAlertTemplate} from "@/services/warning";
import {addClusterGroup, updateClusterGroup} from "@/services/clusterGroup";

interface Props {
    detail?: any;
    type?: number; // 1:编辑 2:克隆
}


const clusterGroupForm: React.FC<Props> = ({ type, detail = {} }) => {

    const { t } = useTranslation();
    const history = useHistory();
    const [form] = Form.useForm();

    const addSubmit = () => {
        form
            .validateFields()
            .then(async (values) => {
                if (type === 1) {
                    const res = await updateClusterGroup(detail.id,values );
                    if (res.err) {
                        message.error(res.error);
                    } else {
                        message.success(t('编辑成功！'));
                        history.push('/cluster-group');
                    }
                } else {
                    const res = await addClusterGroup(values);
                    if (res.err) {
                        message.error(res.error);
                    } else {
                        message.success(t('编辑成功！'));
                        history.push('/cluster-group');
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <div className='operate_con'>
            <Form
                form={form}
                className='strategy-form'
                layout='vertical'
                initialValues={{
                    ...parseValues(detail),
                    cate: detail.cate,
                }}
            >
                <Space direction='vertical' style={{ width: '100%' }}>
                    <Card title={t('基本配置')}>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    label={t('数据源组名称：')}
                                    name='name'
                                    rules={[
                                        {
                                            required: true,
                                            message: t('数据源组名称不能为空'),
                                        },
                                    ]}
                                >
                                    <Input placeholder={t('请输入数据源组名称')} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <AdvancedWrap var='VITE_IS_ALERT_ES_DS'>
                                    {(visible) => {
                                        return <CateSelect form={form} visible={visible} />;
                                    }}
                                </AdvancedWrap>
                            </Col>
                            <Col span={12}>
                                <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.cate !== curValues.cate} noStyle>
                                    {({ getFieldValue }) => {
                                        return <ClusterSelect form={form} cate={getFieldValue('cate')} />;
                                    }}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                    <Form.Item
                        style={{
                            marginTop: 20,
                        }}
                    >
                        <Button type='primary' onClick={addSubmit}  style={{ marginRight: '8px' }}>
                            {type === 1 ? t('编辑') : type === 2 ? t('克隆') : t('创建')}
                        </Button>

                        <Button
                            onClick={() => {
                                history.push('/cluster-group');
                            }}
                        >
                            {t('取消')}
                        </Button>
                    </Form.Item>
                </Space>
            </Form>
        </div>
    );
}

export default clusterGroupForm;
