import React from 'react';
import { Form, Select } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';

const typeNameMap = {
  prometheus: 'Prometheus',
  clickhouse:'Clickhouse',
  influxDB:'InfluxDB',
  elasticsearch: 'Elasticsearch',
  'aliyun-sls': '阿里云SLS',
    vm_group:'VmClusterGroup',
};

export default function index({ visible, form }) {
  const options = visible ? ['prometheus', 'elasticsearch', 'aliyun-sls','clickhouse','influxDB','vm_group'] : ['prometheus','clickhouse','influxDB','vm_group'];
  return (
    <Form.Item label='数据源类型' name='cate' initialValue='prometheus'>
      <Select
        suffixIcon={<CaretDownOutlined />}
        onChange={(val) => {
          const values: any = {
            cluster: [],
            prom_ql: '',
          };
          if (val === 'elasticsearch') {
            values.queries = [
              {
                ref: 'A',
                value: {
                  func: 'count',
                },
                date_field: '@timestamp',
                interval: 1,
                interval_unit: 'min',
              },
            ];
            values.triggers = [
              {
                mode: 0,
                severity: 1,
              },
            ];
          }
          form.setFieldsValue(values);
        }}
      >
        {options.map((item) => (
          <Select.Option key={item} value={item}>
            {typeNameMap[item]}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}
