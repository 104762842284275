import React from "react";
import {useTranslation} from "react-i18next";
import PageLayout from "@/components/pageLayout";
import DatasourceList from "@/pages/warning/datasource/components/datasourceTable";

const DatasourceListTable: React.FC = () =>{
    const { t } = useTranslation();
    return (
        <PageLayout title={t('数据源管理')} hideCluster>
            <div className='strategy-content'>
                <DatasourceList />
            </div>
        </PageLayout>
    );
}

export default DatasourceListTable;
