import React from "react";
import {useTranslation} from "react-i18next";
import PageLayout from "@/components/pageLayout";
import ClusterGroupForm from "@/pages/warning/clusterGroup/components/clusterGroupForm";


const ClusterGroupAdd: React.FC = () => {
    const { t } = useTranslation();
    return (
        <PageLayout title={t('数据源组管理')} showBack hideCluster>
            <ClusterGroupForm />
        </PageLayout>
    );
};

export default ClusterGroupAdd;
