import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { List, Card, Form, Input, Row, Col, Button, message, Space, Select, InputNumber } from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { addAlertTemplate, updateAlertTemplate, listAlertTemplatePlatform } from '@/services/warning';
import AdvancedWrap from '@/components/AdvancedWrap';
import CateSelect from './CateSelect';
import ClusterSelect from './ClusterSelect';
import { parseValues } from './utils';
export { ClusterAll } from './ClusterSelect';

const { TextArea } = Input;

interface Props {
  detail?: any;
  type?: number; // 1:编辑 2:克隆
}

function isValidEnVar() {
  const contentRegExp = /^[a-zA-Z][a-zA-Z0-9]*$/;
  return {
    validator(_, value) {
      const valid = value && contentRegExp.test(value);
      return valid ? Promise.resolve() : Promise.reject(new Error('变量格式不正确，请检查！'));
    },
  };
}

const operateForm: React.FC<Props> = ({ type, detail = {} }) => {
  const { t } = useTranslation();
  const history = useHistory(); // 创建的时候默认选中的值
  const [form] = Form.useForm();
  // const [notifyGroups, setNotifyGroups] = useState<any[]>([]);

  // useEffect(() => {
  // if (type == 1) {
  //   const groups = (detail.notify_groups_obj ? detail.notify_groups_obj.filter((item) => !notifyGroups.find((i) => item.id === i.id)) : []).concat(notifyGroups);
  //   setNotifyGroups(groups);
  // }
  // }, [JSON.stringify(detail)]);

  const tishi =
    '查询语句里面的预置变量，请用{{.变量英文名称}}来填写。假设上面模板变量里定义了一个变量（中文名为名称空间，英文名为ns），则promql模板语句为container_cpu_usage_seconds_total{namespace={{.ns}},container!="POD"} > 90';

  const addSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        //判断ql语句是否用到了平台变量
        let platform = platforms.find((item) => item.id === values.platform_id);
        if (platform.varsList?.length > 0) {
          let usePlatformVar = true;
          platform.varsList.map((item) => {
            values.promql.includes(item.en) ? null : (usePlatformVar = false);
          });
          if (!usePlatformVar) {
            message.error('存在未使用的平台变量，请检查！');
            return;
          }
        }
        // 判断ql语句是否用了模板变量
        let useVar = true;
        values.varsList.map((item) => {
          values.promql.includes(`{{.${item.en}}}`) ? null : (useVar = false);
        });
        if (!useVar) {
          message.error('存在未使用的模板变量，请检查！');
          return;
        }
        if (type === 1) {
          const res = await updateAlertTemplate(values, detail.id);
          if (res.err) {
            message.error(res.error);
          } else {
            message.success(t('编辑成功！'));
            history.push('/alert-template');
          }
        } else {
          const { dat } = await addAlertTemplate([values]);
          let errorNum = 0;
          const msg = Object.keys(dat).map((key) => {
            dat[key] && errorNum++;
            return dat[key];
          });

          if (!errorNum) {
            message.success(`${type === 2 ? t('告警模板克隆成功') : t('告警模板创建成功')}`);
            history.push('/alert-template');
          } else {
            message.error(t(msg));
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [platforms, setPlatforms] = useState<any[]>([]);
  const getAlertTemplatePlatform = async () => {
    const { success, dat } = await listAlertTemplatePlatform(0);
    if (success) {
      setPlatforms(dat || []);
    }
  };
  useEffect(() => {
    getAlertTemplatePlatform();
  }, []);

  return (
    <div className='operate_con'>
      <Form
        form={form}
        className='strategy-form'
        layout='vertical'
        initialValues={{
          ...parseValues(detail),
          cate: detail.cate,
        }}
      >
        <Space direction='vertical' style={{ width: '100%' }}>
          <Card title={t('基本配置')}>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  label='模板归属平台'
                  name='platform_id'
                  rules={[{ required: true, message: '平台不能为空！' }]}
                  tooltip={'若不存在平台，请先到归属平台管理页面进行创建！'}
                >
                  <Select options={platforms.map((item) => ({ label: item.name, value: item.id }))} placeholder='请选择平台' />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.platform_id !== curValues.platform_id}>
              {({ getFieldValue }) => {
                let platform = platforms.find((p) => p.id === getFieldValue('platform_id'));
                let vars = platform?.varsList;
                if (vars?.length > 0) {
                  return (
                    <Form.Item label='平台变量' shouldUpdate>
                      {vars?.map((item) => (
                        <Row key={item.en}>
                          <Col span={6} key={item.cn}>
                            <Button type='link'>中文名: {item.cn}</Button>
                          </Col>
                          <Col span={6} key={item.en}>
                            <Button type='link'>英文名: {item.en}</Button>
                          </Col>
                          <Col span={6} key={item.default}>
                            <Button type='link'>默认值: {item.default}</Button>
                          </Col>
                          <Col span={6} key={item.comment}>
                            <Button type='link'>备注: {item.comment}</Button>
                          </Col>
                        </Row>
                      ))}
                    </Form.Item>
                  );
                }
              }}
            </Form.Item>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  label={t('模板名称：')}
                  name='name'
                  rules={[
                    {
                      required: true,
                      message: t('模板名称不能为空'),
                    },
                  ]}
                >
                  <Input placeholder={t('请输入模板名称')} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <AdvancedWrap var='VITE_IS_ALERT_ES_DS'>
                  {(visible) => {
                    return <CateSelect form={form} visible={visible} />;
                  }}
                </AdvancedWrap>
              </Col>
              <Col span={12}>
                <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.cate !== curValues.cate} noStyle>
                  {({ getFieldValue }) => {
                    return <ClusterSelect form={form} cate={getFieldValue('cate')} />;
                  }}
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label='模板变量'
              tooltip={'若所选平台已包含相同作用的变量，无需再新建！例如已经存在了namespace的平台变量，则不需要再新建了'}
              shouldUpdate
              initialValue={[{}]}
            >
              {() => (
                <>
                  <Form.List
                    name='varsList'
                    rules={[
                      {
                        validator: async (_, varsList) => {
                          if (!varsList || varsList.length === 0) {
                            return Promise.reject(new Error('模板变量不能为空'));
                          }
                        },
                      },
                    ]}
                  >
                    {(fields, { add, remove }, { errors }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <Row key={key}>
                            {/* <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align='baseline'> */}
                            <Form.Item {...restField} name={[name, 'cn']} rules={[{ required: true, message: '中文名不能为空' }]}>
                              <Input placeholder='变量中文名' style={{ width: 120, margin: 3 }} />
                            </Form.Item>
                            <Form.Item {...restField} name={[name, 'en']} rules={[{ required: true, message: '英文名不能为空' }, isValidEnVar]}>
                              <Input placeholder='变量英文名' style={{ width: 120, margin: 3 }} />
                            </Form.Item>
                            <Form.Item {...restField} name={[name, 'type']} rules={[{ required: true, message: '类型不能为空' }]}>
                              <Select
                                // defaultValue='text'
                                // onChange={handleVarTypeChange}
                                options={[
                                  { value: 'text', label: '文本类型' },
                                  { value: 'number', label: '数字类型' },
                                  { value: 'time', label: '时间类型' },
                                ]}
                                style={{ width: 120, margin: 3 }}
                                placeholder='变量类型'
                              />
                            </Form.Item>
                            {form.getFieldValue(['varsList', name, 'type']) === 'time' && (
                              <Form.Item {...restField} name={[name, 'unit']} rules={[{ required: true, message: '不能为空' }]}>
                                <Select
                                  options={[
                                    { value: 's', label: '秒' },
                                    { value: 'm', label: '分' },
                                    { value: 'h', label: '时' },
                                    { value: 'd', label: '天' },
                                    { value: 'w', label: '周' },
                                  ]}
                                  style={{ width: 120, margin: 3 }}
                                  placeholder='时间单位'
                                />
                              </Form.Item>
                            )}
                            <Form.Item {...restField} name={[name, 'default']} rules={[{ required: true, message: '默认值不能为空' }]}>
                              {form.getFieldValue(['varsList', name, 'type']) == 'text' || form.getFieldValue(['varsList', name, 'type']) == undefined ? (
                                <Input placeholder='文本变量默认值' style={{ width: 120, margin: 3 }} />
                              ) : (
                                <InputNumber placeholder='默认值' step={0.1} min={0} style={{ width: 120, margin: 3 }} />
                              )}
                            </Form.Item>
                            {(form.getFieldValue(['varsList', name, 'type']) === 'number' || form.getFieldValue(['varsList', name, 'type']) === 'time') && (
                              <Form.Item {...restField} name={[name, 'min']} rules={[{ required: true, message: '不能为空' }]}>
                                <InputNumber placeholder='最小值' step={0.1} min={0} style={{ width: 120, margin: 3 }} />
                              </Form.Item>
                            )}
                            {(form.getFieldValue(['varsList', name, 'type']) === 'number' || form.getFieldValue(['varsList', name, 'type']) === 'time') && (
                              <Form.Item {...restField} name={[name, 'max']} rules={[{ required: true, message: '不能为空' }]}>
                                <InputNumber placeholder='最大值' step={0.1} min={0} style={{ width: 120, margin: 3 }} />
                              </Form.Item>
                            )}
                            <Form.Item {...restField} name={[name, 'comment']} rules={[{ required: true, message: '注释说明不能为空' }]}>
                              <TextArea allowClear rows={1} placeholder='变量注释说明' autoSize style={{ width: 240, margin: 3 }} />
                            </Form.Item>
                            <MinusCircleOutlined onClick={() => remove(name)} />
                            {/* </Space> */}
                          </Row>
                        ))}
                        <Form.Item>
                          <Button type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                            新增变量
                          </Button>
                          <Form.ErrorList errors={errors} />
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </>
              )}
            </Form.Item>
            <Form.Item label='模板语句' name='promql' tooltip={tishi} rules={[{ required: true, message: '查询语句模板不能为空！' }]}>
              <TextArea rows={4} placeholder='请输入PromQL模板语句' />
            </Form.Item>
          </Card>
          <Form.Item
            style={{
              marginTop: 20,
            }}
          >
            <Button type='primary' onClick={addSubmit} style={{ marginRight: '8px' }}>
              {type === 1 ? t('提交') : type === 2 ? t('克隆') : t('创建')}
            </Button>

            <Button
              onClick={() => {
                history.push('/alert-template');
              }}
            >
              {t('取消')}
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </div>
  );
};

export default operateForm;
