import request from "@/utils/request";
import {RequestMethod} from "@/store/common";


export const getDatasourceList = function () {
    return request('/api/n9e/datasource',{
        method:RequestMethod.Get
    })
}

export const  delDatasource = function (id){
    return request('/api/n9e/datasource/'+id,{
        method:RequestMethod.Delete
    })
}

export const updateDatasource = function (id,data){
    return request('/api/n9e/datasource/'+id,{
        method:RequestMethod.Put,
        data
    })
}

export const getDatasourceById = function (id){
    return request('/api/n9e/datasource/'+id,{
        method:RequestMethod.Get
    })
}

export const addDatasourceDetail = function (data){
    return request('/api/n9e/datasource',{
        method:RequestMethod.Post,
        data
    })
}
