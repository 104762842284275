import React from "react";
import {useTranslation} from "react-i18next";
import PageLayout from "@/components/pageLayout";
import DatasourceForm from "@/pages/warning/datasource/components/datasourceForm";

const DatasourceDetailAdd: React.FC = () => {
    const { t } = useTranslation();
    return (
        <PageLayout title={t('数据源管理')} showBack hideCluster>
            <DatasourceForm />
        </PageLayout>
    );
};

export default DatasourceDetailAdd;
