import React, { useMemo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PageLayout from '@/components/pageLayout';
import PlatformOperateForm from './components/platformOperateForm';
import { useTranslation } from 'react-i18next';
import { getAlertTemplatePlatform } from '@/services/warning';
import { useQuery } from '@/utils';

import './index.less';

const AlertTemplatePlatformEdit: React.FC = () => {
  const { t } = useTranslation();
  const query = useQuery();
  const isClone = query.get('mode');
  const params: any = useParams();
  const id = useMemo(() => {
    return params.id;
  }, [params]);
  const [curAlertTemplatePlatform, setCurAlertTemplatePlatform] = useState<any>({});
  useEffect(() => {
    getAlertTemplateDetail();
    return () => {};
  }, [id]);

  const getAlertTemplateDetail = async () => {
    const res = await getAlertTemplatePlatform(id);
    setCurAlertTemplatePlatform(res.dat || {});
  };

  return (
    <PageLayout title={t('模板归属平台')} showBack backPath='/alert-template/platform' hideCluster>
      {curAlertTemplatePlatform.id && <PlatformOperateForm detail={curAlertTemplatePlatform} type={!isClone ? 1 : 2} />}
    </PageLayout>
  );
};

export default AlertTemplatePlatformEdit;
