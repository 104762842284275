import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import PageLayout from '@/components/pageLayout';
import { Button, Input, message, List, Row, Col, Pagination, Modal } from 'antd';
import { DeleteTwoTone, EditOutlined, DeleteOutlined, SearchOutlined, UserOutlined, SmallDashOutlined, InfoCircleOutlined } from '@ant-design/icons';
import BaseTable, { IBaseTableProps } from '@/components/BaseTable';
import RoleInfoModal from './component/createModal';
import DelPopover from './component/delPopover';
import { RootState, accountStoreState } from '@/store/accountInterface';
import { addRole, updateRole, deleteRole, getRole } from '@/services/role';
import { User, Team, UserType, ActionType, TeamInfo, Role } from '@/store/manageInterface';
import './index.less';
import { ColumnsType } from 'antd/lib/table';
import { color } from 'echarts';
import { useTranslation } from 'react-i18next';
const { confirm } = Modal;

export const PAGE_SIZE = 20;

const Resource: React.FC = () => {
  const { t } = useTranslation();

  const [activeKey, setActiveKey] = useState<UserType>(UserType.User);
  const [visible, setVisible] = useState<boolean>(false);
  const [action, setAction] = useState<ActionType>();
  const [userId, setUserId] = useState<string>('');
  const [teamId, setTeamId] = useState<string>('');
  const [memberId, setMemberId] = useState<string>('');
  const [query, setQuery] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const roleRef = useRef(null as any);
  let { profile } = useSelector<RootState, accountStoreState>((state) => state.account);

  const [roleId, setRoleId] = useState<string>('');

  const roleColumn: ColumnsType<Role> = [
    {
      title: t('角色名'),
      dataIndex: 'name',
      ellipsis: true,
    },
    {
      title: t('备注'),
      dataIndex: 'note',
      ellipsis: true,
    },
    {
      title: t('归属业务树'),
      dataIndex: 'app_tree',
      // ellipsis: true,
    },
  ];
  const roleColumns: ColumnsType<Role> = [
    ...roleColumn,
    {
      title: t('操作'),
      width: '240px',
      render: (text: string, record) => (
        <>
          <Button className='oper-name' type='link' onClick={() => handleClick(ActionType.EditRole, record.id)}>
            {t('编辑')}
          </Button>

          <a
            style={{
              color: 'red',
              marginLeft: '16px',
            }}
            onClick={() => {
              confirm({
                title: t('是否删除该记录'),
                onOk: () => {
                  deleteRole(record.id).then((_) => {
                    message.success(t('删除成功'));
                    handleClose();
                  });
                },
                onCancel: () => { },
              });
            }}
          >
            {t('删除')}
          </a>
        </>
      ),
    },
  ];

  if (!profile.roles.includes('Admin')) {
    roleColumns.pop(); //普通用户不展示操作列
  }

  const getList = () => {
    roleRef.current.refreshList();
  };

  const handleClick = (type: ActionType, id?: string) => {
    if (id) {
      activeKey === UserType.User ? setRoleId(id) : setTeamId(id);
    } else {
      activeKey === UserType.User ? setRoleId('') : setTeamId('');
    }

    setAction(type);
    setVisible(true);
  };

  // 弹窗关闭回调
  const handleClose = () => {
    setVisible(false);
    getList();
  };

  const onSearchQuery = (e) => {
    let val = e.target.value;
    setQuery(val);
  };

  return (
    <PageLayout title={t('角色管理')} icon={<UserOutlined />} hideCluster>
      <div className='user-manage-content'>
        <div className='user-content'>
          <Row className='event-table-search'>
            <div className='event-table-search-left'>
              <Input className={'searchInput'} prefix={<SearchOutlined />} onPressEnter={onSearchQuery} placeholder={t('角色名')} />
            </div>
            <div className='event-table-search-right'>
              {activeKey === UserType.User && profile.roles.includes('Admin') && (
                <div className='user-manage-operate'>
                  <Button type='primary' onClick={() => handleClick(activeKey === UserType.User ? ActionType.CreateRole : t('创建角色'))} ghost>
                    {t('创建角色')}
                  </Button>
                </div>
              )}
            </div>
          </Row>
          <BaseTable
            ref={roleRef}
            fetchHandle={getRole}
            columns={roleColumns}
            rowKey='id'
            needPagination={true}
            fetchParams={{
              query,
            }}
            tableLayout='auto'
          ></BaseTable>
        </div>

        <RoleInfoModal
          visible={visible}
          action={action as ActionType}
          width={activeKey === UserType.User ? 500 : 700}
          userType={activeKey}
          onClose={handleClose}
          userId={userId}
          roleId={roleId}
        />
      </div>
    </PageLayout>
  );
};

export default Resource;
