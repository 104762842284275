import React, {useEffect, useMemo, useState} from "react";
import PageLayout from "@/components/pageLayout";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {useQuery} from "@/utils";
import {getDatasourceById} from "@/services/datasource";
import DatasourceForm from "@/pages/warning/datasource/components/datasourceForm";

const DatasourceEdit: React.FC = () => {
    const { t } = useTranslation();
    const params: any = useParams();
    const query = useQuery();
    const isClone = query.get('mode');
    const [curDatasource, setCurDatasource] = useState<any>({});
    const id = useMemo(() => {
        return params.id;
    }, [params]);
    useEffect(() => {
        getDatasourceDetail();
        return () => {};
    }, [id]);

    const getDatasourceDetail = async () => {
        const res = await getDatasourceById(id);
        setCurDatasource(res.dat.data || {});
    };


    return(
        <PageLayout title={t('数据源')} showBack backPath='/datasource'>
            {curDatasource.id && <DatasourceForm detail={curDatasource} type={!isClone ? 1 : 2} />}
        </PageLayout>
    )
}

export default DatasourceEdit;
