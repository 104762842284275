import React from 'react';
import PageLayout from '@/components/pageLayout';
import AlertTemplateList from './components/templateTable';
import { useTranslation } from 'react-i18next';

const AlertTemplateListTable: React.FC = () => {
  const { t } = useTranslation();
  return (
    <PageLayout title={t('模板管理')} hideCluster>
      <div className='strategy-content'>
        <AlertTemplateList />
      </div>
    </PageLayout>
  );
};

export default AlertTemplateListTable;
