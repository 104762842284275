import React, {useEffect, useMemo, useState} from "react";
import {Button, message, Modal, Space, Table} from "antd";
import RefreshIcon from "@/components/RefreshIcon";
import {pageSizeOptionsDefault} from "@/pages/warning/const";
import {useTranslation} from "react-i18next";
import {Link, useHistory} from "react-router-dom";
import {delDatasource, getDatasourceList} from "@/services/datasource";
import {ColumnType} from "antd/lib/table";
import {clusterGroupItem, datasourceItem} from "@/store/warningInterface";
const { confirm } = Modal;

const DatasourceList: React.FC = () =>{

    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [currentDatasourceAll, setCurrentDatasourceAll] = useState([]);
    const [currentDatasource, setCurrentDatasource] = useState([]);
    const history = useHistory();

    useEffect(() => {
        getDatasource();
    },[]);

    useEffect(() => {
        filterData()
    },[currentDatasourceAll])

    const getDatasource = async () => {
        setLoading(true);
        const { success, dat } = await getDatasourceList();
        if (success) {
            setCurrentDatasourceAll(dat.list || []);
            setLoading(false);
        }
    }
    const  filterData = () => {
        const data = JSON.parse(JSON.stringify(currentDatasourceAll));
        setCurrentDatasource(data || []);
    }

    const refreshList = () => {
        getDatasource();
    };

    const goToAddDatasource = () => {
        history.push(`/datasource/add`);
    }

    const columns: ColumnType<datasourceItem>[] = [
        {
            title: t('名称'),
            dataIndex: 'cluster_name',
            width: 220,
            render: (data, record) => {
                return (
                    <Link
                        className='table-active-text'
                        to={{
                            pathname: `/datasource/edit/${record.id}`,
                        }}
                    >
                        {data}
                    </Link>
                );
            },
        },
        {
            title: t('数据源类型'),
            dataIndex: 'type',
            width:300
        },
        {
            title: t('数据源链接'),
            dataIndex: 'url',
        },
        {
            title: t('用户名'),
            dataIndex: 'basic_auth_user',
        },
        {
            title: t('操作'),
            dataIndex: 'operator',
            width: 220,
            render: (data, record: any) => {
                return (
                    <div className='table-operator-area'>
                        <div
                            className='table-operator-area-warning'
                            onClick={() => {
                                confirm({
                                    title: t('是否删除该数据源?'),
                                    onOk: () => {
                                        delDatasource(record.id).then(() => {
                                            message.success(t('删除成功'));
                                            refreshList();
                                        });
                                    },
                                    onCancel() {},
                                });
                            }}
                        >
                            {t('删除')}
                        </div>
                    </div>
                );
            },
        },
    ]

    const toOneArr = (arr, res, name) => {
        arr.forEach((ele) => {
            if (Array.isArray(ele)) {
                toOneArr(ele, res, name);
            } else {
                res.push(ele[name]);
            }
        });
    };

    return(
        <div className='strategy-table-content'>
            <div className='strategy-table-search table-handle'>
                <Space>
                    <RefreshIcon
                        onClick={() => {
                            refreshList();
                        }}
                    />
                </Space>
                <div className='strategy-table-search-right'>
                    <Button type='primary' onClick={goToAddDatasource}  className='strategy-table-search-right-create' ghost>
                        {t('新增数据源')}
                    </Button>
                </div>
            </div>

            <Table
                rowKey='id'
                pagination={{
                    total: currentDatasource.length,
                    showQuickJumper: true,
                    showSizeChanger: true,
                    showTotal: (total) => {
                        return `共 ${total} 条数据`;
                    },
                    pageSizeOptions: pageSizeOptionsDefault,
                    defaultPageSize: 30,
                }}
                loading={loading}
                dataSource={currentDatasource}
                columns={columns}
            />
        </div>
    )
}

export default DatasourceList;
