import React, {useEffect, useMemo, useState} from "react";
import {delClusterGroup, getClusterGroupList} from "@/services/clusterGroup";
import { ColumnType } from 'antd/lib/table';
import {clusterGroupItem, templateItem} from "@/store/warningInterface";
import {useTranslation} from "react-i18next";
import {Button, Dropdown, message, Modal, Space, Table} from "antd";
import RefreshIcon from "@/components/RefreshIcon";
import {pageSizeOptionsDefault} from "@/pages/warning/const";
import {Link, useHistory} from "react-router-dom";
const { confirm } = Modal;

const ClusterGroupList: React.FC = () => {

    const [currentClusterGroupDataAll, setCurrentClusterGroupDataAll] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentGroupData, setCurrentGroupData] = useState([]);
    const { t } = useTranslation();
    const history = useHistory();

    useEffect(() => {
        getClusterGroups();
    },[]);

    useEffect(() => {
        filterData()
    },[currentClusterGroupDataAll])

    const getClusterGroups = async () => {
        setLoading(true);
        const { success, dat } = await getClusterGroupList(0);
        if (success) {
            setCurrentClusterGroupDataAll(dat.list || []);
            setLoading(false);
        }
    }

    const  filterData = () => {
        const data = JSON.parse(JSON.stringify(currentClusterGroupDataAll));
        setCurrentGroupData(data || []);
    }

    const refreshList = () => {
        getClusterGroups();
    };

    const goToAddClusterGroup = () => {
        history.push(`/cluster-group/add`);
    }

    const columns: ColumnType<clusterGroupItem>[] = [
        {
            title: t('名称'),
            dataIndex: 'name',
            width: 220,
            render: (data, record) => {
                return (
                    <Link
                        className='table-active-text'
                        to={{
                            pathname: `/cluster-group/edit/${record.id}`,
                        }}
                    >
                        {data}
                    </Link>
                );
            },
        },
        {
            title: t('绑定数据源'),
            dataIndex: 'cluster',
        },
        {
            title: t('操作'),
            dataIndex: 'operator',
            width: 220,
            render: (data, record: any) => {
                return (
                    <div className='table-operator-area'>
                        <div
                            className='table-operator-area-warning'
                            onClick={() => {
                                confirm({
                                    title: t('是否删除该告警规则?'),
                                    onOk: () => {
                                        delClusterGroup(record.id).then(() => {
                                            message.success(t('删除成功'));
                                            refreshList();
                                        });
                                    },
                                    onCancel() {},
                                });
                            }}
                        >
                            {t('删除')}
                        </div>
                    </div>
                );
            },
        },
    ]

    const toOneArr = (arr, res, name) => {
        arr.forEach((ele) => {
            if (Array.isArray(ele)) {
                toOneArr(ele, res, name);
            } else {
                res.push(ele[name]);
            }
        });
    };

    return(
        <div className='strategy-table-content'>
            <div className='strategy-table-search table-handle'>
                <Space>
                    <RefreshIcon
                        onClick={() => {
                            refreshList();
                        }}
                    />

                    {/*<SearchInput className={'searchInput'} placeholder={t('搜索名称或标签')} onSearch={setQuery} allowClear />*/}
                </Space>
                <div className='strategy-table-search-right'>
                    <Button type='primary' onClick={goToAddClusterGroup}  className='strategy-table-search-right-create' ghost>
                        {t('新增数据源组')}
                    </Button>
                </div>
            </div>

            <Table
                rowKey='id'
                pagination={{
                    total: currentGroupData.length,
                    showQuickJumper: true,
                    showSizeChanger: true,
                    showTotal: (total) => {
                        return `共 ${total} 条数据`;
                    },
                    pageSizeOptions: pageSizeOptionsDefault,
                    defaultPageSize: 30,
                }}
                loading={loading}
                dataSource={currentGroupData}
                columns={columns}
            />
        </div>
    )
};

export default ClusterGroupList;
