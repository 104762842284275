// 告警模板
import React, { useEffect, useState, useMemo } from 'react';
import _ from 'lodash';
import { Tag, Button, Modal, message, Switch, Dropdown, Table, Tabs, Select, Space } from 'antd';
import SearchInput from '@/components/BaseSearchInput';
import { useHistory, Link } from 'react-router-dom';
import { templateItem, strategyStatus } from '@/store/warningInterface';
import { ColumnType } from 'antd/lib/table';
import { pageSizeOptionsDefault } from '../../const';
import dayjs from 'dayjs';
import RefreshIcon from '@/components/RefreshIcon';
import ColorTag from '@/components/ColorTag';
// import { DownOutlined } from '@ant-design/icons';
const { confirm } = Modal;
import { useTranslation } from 'react-i18next';
import { deleteAlertTemplatePlatform, listAlertTemplatePlatform, batchDeleteAlertTemplatePlatform } from '@/services/warning';

const AlertTemplatePlatformList: React.FC = () => {
  const [clusters, setClusters] = useState<string[]>([]);
  const { t } = useTranslation();
  const history = useHistory();
  const [selectRowKeys, setSelectRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<templateItem[]>([]);
  const [query, setQuery] = useState<string>('');
  const [currentAlertTemplatePlatformDataAll, setCurrentAlertTemplatePlatformDataAll] = useState([]);
  const [currentTemplateData, setCurrentTemplateData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAlertTemplatePlatformList();
  }, []);

  useEffect(() => {
    filterData();
  }, [query, clusters, currentAlertTemplatePlatformDataAll]);

  const getAlertTemplatePlatformList = async () => {
    setLoading(true);
    const { success, dat } = await listAlertTemplatePlatform(0);
    if (success) {
      setCurrentAlertTemplatePlatformDataAll(dat || []);
      setLoading(false);
    }
  };

  const filterData = () => {
    const data = JSON.parse(JSON.stringify(currentAlertTemplatePlatformDataAll));
    const res = data.filter((item) => {
      const lowerCaseQuery = query.toLowerCase();
      return (
        (item.name.toLowerCase().indexOf(lowerCaseQuery) > -1 || item.append_tags.join(' ').toLowerCase().indexOf(lowerCaseQuery) > -1) &&
        ((clusters && clusters?.indexOf(item.cluster) > -1) || clusters?.length === 0)
      );
    });
    setCurrentTemplateData(res || []);
  };

  const goToAddAlertTemplatePlatform = () => {
    history.push(`/alert-template/platform/add`);
  };

  const refreshList = () => {
    getAlertTemplatePlatformList();
  };

  const columns: ColumnType<templateItem>[] = [
    {
      title: t('平台名称'),
      dataIndex: 'name',
      render: (data, record) => {
        return (
          <Link
            className='table-active-text'
            to={{
              pathname: `/alert-template/platform/edit/${record.id}`,
            }}
          >
            {data}
          </Link>
        );
      },
    },
    {
      title: t('是否有平台变量'),
      dataIndex: 'has_var',
      render: (data) => {
        if (data) {
          return t('是');
        } else {
          return t('否');
        }
      },
      width: '150px',
    },
    {
      title: t('平台变量'),
      dataIndex: 'varsList',
      render: (data) => {
        if (data != undefined) {
          return (
            (data.length &&
              data.map((tag: object, index: number) => {
                return <ColorTag text={tag['cn'] + '(' + tag['en'] + ')'} key={index}></ColorTag>;
              })) || <div></div>
          );
        }
      },
    },
    {
      title: t('创建时间'),
      dataIndex: 'create_at',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.create_at - b.create_at,
      width: 200,
      render: (text: string) => dayjs(Number(text) * 1000).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: t('更新时间'),
      dataIndex: 'update_at',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.update_at - b.update_at,
      width: 200,
      render: (text: string) => dayjs(Number(text) * 1000).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: t('操作'),
      dataIndex: 'operator',
      width: 160,
      render: (data, record: any) => {
        return (
          <div className='table-operator-area'>
            <div
              className='table-operator-area-warning'
              onClick={() => {
                confirm({
                  title: t('是否删除该告警规则?'),
                  onOk: () => {
                    deleteAlertTemplatePlatform(record.id).then(() => {
                      message.success(t('删除成功'));
                      refreshList();
                    });
                  },
                  onCancel() {},
                });
              }}
            >
              {t('删除')}
            </div>
          </div>
        );
      },
    },
  ];

  const toOneArr = (arr, res, name) => {
    arr.forEach((ele) => {
      if (Array.isArray(ele)) {
        toOneArr(ele, res, name);
      } else {
        res.push(ele[name]);
      }
    });
  };

  // const menu = useMemo(() => {
  //   return (
  //     <ul className='ant-dropdown-menu'>
  //       <li
  //         className='ant-dropdown-menu-item'
  //         onClick={() => {
  //           if (selectRowKeys.length) {
  //             confirm({
  //               title: t('是否批量删除告警规则?'),
  //               onOk: () => {
  //                 batchDeleteAlertTemplatePlatform(selectRowKeys as number[]).then(() => {
  //                   message.success(t('删除成功'));
  //                   refreshList();
  //                 });
  //               },

  //               onCancel() {},
  //             });
  //           } else {
  //             message.warning(t('未选择任何规则'));
  //           }
  //         }}
  //       >
  //         <span>{t('批量删除规则')}</span>
  //       </li>
  //     </ul>
  //   );
  // }, [selectRowKeys, t]);

  return (
    <div className='strategy-table-content'>
      <div className='strategy-table-search table-handle'>
        <Space>
          <RefreshIcon
            onClick={() => {
              refreshList();
            }}
          />

          <SearchInput className={'searchInput'} placeholder={t('搜索名称或标签')} onSearch={setQuery} allowClear />
        </Space>
        <div className='strategy-table-search-right'>
          <Button type='primary' onClick={goToAddAlertTemplatePlatform} className='strategy-table-search-right-create' ghost>
            {t('新增告警模板')}
          </Button>
          {/* <div className={'table-more-options'}>
            <Dropdown overlay={menu} trigger={['click']}>
              <Button onClick={(e) => e.stopPropagation()}>
                {t('更多操作')}
                <DownOutlined
                  style={{
                    marginLeft: 2,
                  }}
                />
              </Button>
            </Dropdown>
          </div> */}
        </div>
      </div>

      <Table
        rowKey='id'
        // sticky
        pagination={{
          total: currentTemplateData.length,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total) => {
            return `共 ${total} 条数据`;
          },
          pageSizeOptions: pageSizeOptionsDefault,
          defaultPageSize: 30,
        }}
        loading={loading}
        dataSource={currentTemplateData}
        rowSelection={{
          selectedRowKeys: selectedRows.map((item) => item.id),
          onChange: (selectedRowKeys: React.Key[], selectedRows: templateItem[]) => {
            setSelectRowKeys(selectedRowKeys);
            setSelectedRows(selectedRows);
          },
        }}
        columns={columns}
        expandable={{
          expandedRowRender: (record) => <p style={{ margin: 10 }}>{record.promql}</p>,
        }}
      />
    </div>
  );
};

export default AlertTemplatePlatformList;
