import React, { useEffect, useState, useImperativeHandle, ReactNode } from 'react';
import { Form, Input, TreeSelect } from 'antd';
import { layout } from '../../const';
import { getRoleInfo } from '@/services/role';
import { RoleProps, Role } from '@/store/manageInterface';
import { useTranslation } from 'react-i18next';
import { getAppTree } from '@/services/appTree';

const RoleForm = React.forwardRef<ReactNode, RoleProps>((props, ref) => {
  const { t } = useTranslation();
  const { roleId } = props;
  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState<Role>();
  const [loading, setLoading] = useState<boolean>(true);
  const [appTreeList, setAppTreeList] = useState([]);

  const [selectedAppTree, setSelectedAppTree] = useState([]);

  useImperativeHandle(ref, () => ({
    form: form,
  }));
  useEffect(() => {
    if (roleId) {
      getRoleInfoDetail(roleId);
    } else {
      setLoading(false);
    }

    getCmdbAppTree();
  }, []);

  const getCmdbAppTree = async () => {
    const res = await getAppTree();
    let appTreeList = res.children || [];
    setAppTreeList(appTreeList);
  };

  const onChangeAppTree = (newValue) => {
    setSelectedAppTree(newValue);
  };

  const getRoleInfoDetail = (id: string) => {
    getRoleInfo(id).then((data: Role) => {
      setInitialValues(data);
      setLoading(false);
    });
  };

  // 校验是否选了业务树
  function isValidAppTree() {
    return {
      validator(_, value) {
        let isValid = true;
        if (value) {
          if (value.length < 1) {
            isValid = false;
          }
        } else {
          isValid = false;
        }

        return isValid ? Promise.resolve() : Promise.reject(new Error('归属业务不能为空！'));
      },
    };
  }

  return !loading ? (
    <Form {...layout} form={form} initialValues={initialValues} preserve={false}>
      {!roleId && (
        <Form.Item
          label={t('角色名')}
          name='name'
          rules={[
            {
              required: true,
              message: t('角色名不能为空！'),
            },
          ]}
        >
          <Input />
        </Form.Item>
      )}

      <Form.Item
        label={t('备注')}
        name='note'
        rules={[
          {
            required: true,
            message: t('备注不能为空！'),
          },
        ]}
      >
        <Input placeholder='请输入有用信息，如对该角色的阐释等' />
      </Form.Item>

      <Form.Item
        label={t('归属业务')}
        name='app_tree'
        rules={[
          {
            required: true,
            message: t('归属业务不能为空'),
          },
          isValidAppTree,
        ]}
      >
        <TreeSelect
          style={{ width: '100%' }}
          value={selectedAppTree}
          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
          placeholder='请输入归属业务'
          onChange={onChangeAppTree}
          treeData={appTreeList}
          fieldNames={{ label: 'name', value: 'uid', children: 'children' }}
          treeNodeFilterProp='title'
          labelInValue
          multiple
          treeCheckable
          allowClear
          treeDefaultExpandAll
          showSearch
          showCheckedStrategy={TreeSelect.SHOW_ALL}
        />
      </Form.Item>
    </Form>
  ) : null;
});
export default RoleForm;
