import React from 'react';
import PageLayout from '@/components/pageLayout';
import AlertTemplatePlatformList from './components/platformTable';
import { useTranslation } from 'react-i18next';

const AlertTemplatePlatformListTable: React.FC = () => {
  const { t } = useTranslation();
  return (
    <PageLayout title={t('模板归属平台管理')} hideCluster>
      <div className='strategy-content'>
        <AlertTemplatePlatformList />
      </div>
    </PageLayout>
  );
};

export default AlertTemplatePlatformListTable;
