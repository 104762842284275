import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import { Card, Form, Input, InputNumber, Select, Row, Col, Button, TimePicker, message, Space, Switch, Tooltip, Tag, TreeSelect } from 'antd';
import { QuestionCircleFilled, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
// import { getTeamInfoList, getNotifiesList } from '@/services/manage';
import { parseValues } from './utils';
export { ClusterAll } from './ClusterSelect';
const { Option } = Select;
import { getAppTree } from '@/services/appTree';
import { createAlertByTemplate, createAlertBySubscribePlatform } from '@/services/warning';

interface Props {
  detail?: any;
  // type?: number;
  // currentStep?: number;
  promql?: string;
  tid?: number;
  childClick: Function;
  cluster?: string;
  platformId?: number;
  subscribeAll?: boolean;
  templateVars?: object;
  platformVars?: object;
}

// 校验单个标签格式是否正确
function isTagValid(tag) {
  const contentRegExp = /^[a-zA-Z_][\w]*={1}[^=]+$/;
  return {
    isCorrectFormat: contentRegExp.test(tag.toString()),
    isLengthAllowed: tag.toString().length <= 64,
  };
}

// 渲染标签
function tagRender(content) {
  const { isCorrectFormat, isLengthAllowed } = isTagValid(content.value);
  return isCorrectFormat && isLengthAllowed ? (
    <Tag closable={content.closable} onClose={content.onClose}>
      {content.value}
    </Tag>
  ) : (
    <Tooltip title={isCorrectFormat ? '标签长度应小于等于 64 位' : '标签格式应为 key=value。且 key 以字母或下划线开头，由字母、数字和下划线组成。'}>
      <Tag color='error' closable={content.closable} onClose={content.onClose} style={{ marginTop: '2px' }}>
        {content.value}
      </Tag>
    </Tooltip>
  );
}

// 校验所有标签格式
function isValidFormat() {
  return {
    validator(_, value) {
      const isInvalid =
        value &&
        value.some((tag) => {
          const { isCorrectFormat, isLengthAllowed } = isTagValid(tag);
          if (!isCorrectFormat || !isLengthAllowed) {
            return true;
          }
        });
      return isInvalid ? Promise.reject(new Error('标签格式不正确，请检查！')) : Promise.resolve();
    },
  };
}

// 校验是否选了业务树
function isValidAppTree() {
  return {
    validator(_, value) {
      let isValid = true;
      if (value.value == '' || value.label == '' || value.value == undefined || value.label == undefined) {
        isValid = false;
      }
      return isValid ? Promise.resolve() : Promise.reject(new Error('归属业务不能为空！'));
    },
  };
}

const addAlertForm: React.FC<Props> = ({ platformVars = {}, templateVars = {}, subscribeAll, platformId, cluster, tid, promql, detail = {}, childClick }) => {
  const { t } = useTranslation();
  const history = useHistory(); // 创建的时候默认选中的值
  const [form] = Form.useForm();
  // const [contactList, setInitContactList] = useState([]);
  // const [notifyGroups, setNotifyGroups] = useState<any[]>([]);
  const [appTreeList, setAppTreeList] = useState([]);
  const [selectedAppTree, setSelectedAppTree] = useState<{ [key: string]: any }>({});

  const onChange = (newValue: Object) => {
    setSelectedAppTree(newValue);
  };

  useEffect(() => {
    // getNotifyChannel();
    // getGroups('');
    getCmdbAppTree();
    return () => {};
  }, []);

  // useEffect(() => {
  //   if (type == 1) {
  //     const groups = (detail.notify_groups_obj ? detail.notify_groups_obj.filter((item) => !notifyGroups.find((i) => item.id === i.id)) : []).concat(notifyGroups);
  //     setNotifyGroups(groups);
  //   }
  // }, [JSON.stringify(detail)]);

  const enableDaysOfWeekOptions = [t('周日'), t('周一'), t('周二'), t('周三'), t('周四'), t('周五'), t('周六')].map((v, i) => {
    return <Option value={String(i)} key={i}>{`${v}`}</Option>;
  });

  const handleGoback = () => {
    childClick();
  };

  // const getNotifyChannel = async () => {
  //   const res = await getNotifiesList();
  //   let contactList = res || [];
  //   setInitContactList(contactList);
  // };

  // const getGroups = async (str) => {
  //   const res = await getTeamInfoList({ query: str });
  //   const data = res.dat || res;
  //   const combineData = (detail.notify_groups_obj ? detail.notify_groups_obj.filter((item) => !data.find((i) => item.id === i.id)) : []).concat(data);
  //   setNotifyGroups(combineData || []);
  // };

  const getCmdbAppTree = async () => {
    const res = await getAppTree();
    let appTreeList = res.children || [];
    setAppTreeList(appTreeList);
  };

  const addSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        values.app_tree_uid = values.app_tree.value;
        values.app_tree_name = values.app_tree.label;
        values.severity = 3;
        values.runbook_url = '';
        values.notify_channels = ['cobra'];
        const data = {
          ..._.omit(values, ['effective_time']),
          enable_days_of_weeks: values.effective_time.map((item) => item.enable_days_of_week),
          enable_stimes: values.effective_time.map((item) => item.enable_stime.format('HH:mm')),
          enable_etimes: values.effective_time.map((item) => item.enable_etime.format('HH:mm')),
          disabled: !values.enable_status ? 1 : 0,
          notify_recovered: values.notify_recovered ? 1 : 0,
          enable_in_bg: 0,
          callbacks: [],
          // cluster: values.cluster.join(' '),
        };

        let dat = {};
        if (subscribeAll) {
          data.platform_id = platformId;
          data.subscribe_all = subscribeAll;
          // 处理模板变量
          let vars = {};
          Object.keys(templateVars).forEach((key) => {
            if (!vars.hasOwnProperty(key.split('+')[0])) {
              vars[key.split('+')[0]] = {};
            }
            vars[key.split('+')[0]][key.split('+')[1]] = templateVars[key];
          });
          data.tvars = vars;
          data.pvars = platformVars;
          dat = await createAlertBySubscribePlatform(data);
        } else {
          // 不是订阅平台全部告警
          // 填充生成的promql等信息
          data.prom_ql = promql;
          data.tid = tid;
          data.cluster = cluster;
          dat = await createAlertByTemplate(data);
        }
        let errorNum = 0;
        const msg = Object.keys(dat['dat']).map((key) => {
          dat[key] && errorNum++;
          return dat[key];
        });

        if (!errorNum) {
          message.success(t('告警规则创建成功'));
          history.push('/alert-rules');
        } else {
          message.error(t(msg));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className='operate_con'>
      <Form
        form={form}
        className='strategy-form'
        layout='vertical'
        initialValues={{
          severity: 3,
          disabled: 0, // 0:立即启用 1:禁用  待修改
          ...parseValues(detail),
          // cluster: detail.cluster ? detail.cluster.split() : [], // 生效集群
          enable_in_bg: detail?.enable_in_bg === 1,
          effective_time: detail?.enable_etimes
            ? detail?.enable_etimes.map((item, index) => ({
                enable_stime: moment(detail.enable_stimes[index], 'HH:mm'),
                enable_etime: moment(detail.enable_etimes[index], 'HH:mm'),
                enable_days_of_week: detail.enable_days_of_weeks[index],
              }))
            : [
                {
                  enable_stime: moment('00:00', 'HH:mm'),
                  enable_etime: moment('23:59', 'HH:mm'),
                  enable_days_of_week: ['1', '2', '3', '4', '5', '6', '0'],
                },
              ],
          enable_status: detail?.disabled === undefined ? true : !detail?.disabled,
          notify_recovered: detail?.notify_recovered === 1 || detail?.notify_recovered === undefined ? true : false, // 1:启用 0:禁用
          callbacks: !_.isEmpty(detail?.callbacks)
            ? detail.callbacks.map((item) => ({
                url: item,
              }))
            : [{}],
          app_tree: { value: detail.app_tree_uid, label: detail.app_tree_name },
        }}
      >
        <Space direction='vertical' style={{ width: '100%' }}>
          <Card title={t('基本配置')}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label={t('规则标题：')}
                  name='name'
                  rules={[
                    {
                      required: true,
                      message: t('规则标题不能为空'),
                    },
                  ]}
                  tooltip={subscribeAll ? t('最终的规则标题，会由平台名+模板名+输入的该值组合而成') : ''}
                >
                  <Input placeholder={t('请输入规则标题')} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t('规则备注：')}
                  name='note'
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input placeholder={t('请输入规则备注')} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label={t('归属业务')}
              name='app_tree'
              rules={[
                {
                  required: true,
                  message: t('归属业务不能为空'),
                },
                isValidAppTree,
              ]}
            >
              <TreeSelect
                showSearch
                style={{ width: '100%' }}
                value={selectedAppTree}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                placeholder='请输入归属业务'
                allowClear
                treeDefaultExpandAll
                onChange={onChange}
                treeData={appTreeList}
                fieldNames={{ label: 'name', value: 'uid', children: 'children' }}
                treeNodeFilterProp='title'
                labelInValue
              />
            </Form.Item>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.cate !== curValues.cate} noStyle>
                  {({ getFieldValue }) => {
                    const cate = getFieldValue('cate');
                    return (
                      <Form.Item
                        name='prom_eval_interval'
                        label={t('执行频率（秒）')}
                        tooltip={
                          cate === 'prometheus'
                            ? t(`每隔${form.getFieldValue('prom_eval_interval')}秒，把PromQL作为查询条件，去查询后端存储，如果查到了数据就表示当次有监控数据触发了规则`)
                            : '每隔15秒，去查询后端存储'
                        }
                        initialValue={60}
                        rules={[
                          {
                            required: true,
                            message: t('执行频率不能为空'),
                          },
                        ]}
                      >
                        <InputNumber min={1} style={{ width: '100%' }} />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.cate !== curValues.cate} noStyle>
                  {({ getFieldValue }) => {
                    const cate = getFieldValue('cate');
                    return (
                      <Form.Item
                        name='prom_for_duration'
                        label={t('持续时长（秒）')}
                        tooltip={
                          cate === 'prometheus'
                            ? t(
                                `通常持续时长大于执行频率，在持续时长内按照执行频率多次执行PromQL查询，每次都触发才生成告警；如果持续时长置为0，表示只要有一次PromQL查询触发阈值，就生成告警`,
                              )
                            : '通常持续时长大于执行频率，在持续时长内按照执行频率多次执行查询，每次都触发才生成告警；如果持续时长置为0，表示只要有一次查询的数据满足告警条件，就生成告警'
                        }
                        initialValue={60}
                        rules={[
                          {
                            required: true,
                            message: t('持续时长不能为空'),
                          },
                        ]}
                      >
                        <InputNumber min={0} style={{ width: '100%' }} />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
            </Row>

            <Form.Item label='附加标签' name='append_tags' rules={[{ required: false, message: '请填写至少一项标签！' }, isValidFormat]}>
              <Select mode='tags' tokenSeparators={[' ']} open={false} placeholder={'标签格式为 key=value ，使用回车或空格分隔'} tagRender={tagRender} />
            </Form.Item>
            <Form.Item label={t('发送告警至指定应用')} name='appoint' valuePropName='checked'>
              <Switch />
            </Form.Item>
          </Card>
          <Card title={t('生效配置')}>
            <Form.Item
              label={t('立即启用')}
              name='enable_status'
              rules={[
                {
                  required: true,
                  message: t('立即启用不能为空'),
                },
              ]}
              valuePropName='checked'
            >
              <Switch />
            </Form.Item>

            <Form.List name='effective_time'>
              {(fields, { add, remove }) => (
                <>
                  <Space>
                    <div style={{ width: 450 }}>
                      生效时间 <PlusCircleOutlined className='control-icon-normal' onClick={() => add()} />
                    </div>
                    <div style={{ width: 110 }}>开始时间</div>
                    <div style={{ width: 110 }}>结束时间</div>
                  </Space>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space
                      key={key}
                      style={{
                        display: 'flex',
                        marginBottom: 8,
                      }}
                      align='baseline'
                    >
                      <Form.Item
                        {...restField}
                        name={[name, 'enable_days_of_week']}
                        style={{ width: 450 }}
                        rules={[
                          {
                            required: true,
                            message: t('请选择生效周期'),
                          },
                        ]}
                      >
                        <Select mode='tags'>{enableDaysOfWeekOptions}</Select>
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'enable_stime']}
                        style={{ width: 110 }}
                        rules={[
                          {
                            required: true,
                            message: t('开始时间不能为空'),
                          },
                        ]}
                      >
                        <TimePicker format='HH:mm' />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'enable_etime']}
                        style={{ width: 110 }}
                        rules={[
                          {
                            required: true,
                            message: t('结束时间不能为空'),
                          },
                        ]}
                      >
                        <TimePicker format='HH:mm' />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}
                </>
              )}
            </Form.List>
          </Card>
          <Card title={t('通知配置')}>
            <Form.Item label={t('是否需要告警丰富')} name='rich' valuePropName='checked'>
              <Switch />
            </Form.Item>
            <Form.Item label={t('启用恢复通知')}>
              <Space>
                <Form.Item name='notify_recovered' valuePropName='checked' style={{ marginBottom: 0 }}>
                  <Switch />
                </Form.Item>
                <Tooltip title={t(`告警恢复时也发送通知`)}>
                  <QuestionCircleFilled />
                </Tooltip>
              </Space>
            </Form.Item>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  label={t('留观时长（秒）')}
                  name='recover_duration'
                  initialValue={0}
                  tooltip={t(`持续${form.getFieldValue('recover_duration')}秒没有再次触发阈值才发送恢复通知`)}
                >
                  <InputNumber min={0} style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={t('重复发送频率（分钟）')}
                  name='notify_repeat_step'
                  initialValue={60}
                  rules={[
                    {
                      required: true,
                      message: t('重复发送频率不能为空'),
                    },
                  ]}
                  tooltip={t(`如果告警持续未恢复，间隔${form.getFieldValue('notify_repeat_step')}分钟之后重复提醒告警接收组的成员`)}
                >
                  <InputNumber min={0} style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={t('最大发送次数')}
                  name='notify_max_number'
                  initialValue={0}
                  rules={[
                    {
                      required: true,
                      message: t('最大发送次数不能为空'),
                    },
                  ]}
                  tooltip={t(`如果值为0，则不做最大发送次数的限制`)}
                >
                  <InputNumber min={0} precision={0} style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <Form.Item
            style={{
              marginTop: 20,
            }}
          >
            <Button type='primary' onClick={handleGoback} style={{ marginRight: '8px' }}>
              上一步
            </Button>
            <Button type='primary' onClick={addSubmit} style={{ marginRight: '8px' }}>
              {/* {type === 1 ? t('编辑') : type === 2 ? t('克隆') : t('创建')} */}
              {t('创建')}
            </Button>
            <Button
              onClick={() => {
                history.push('/alert-rules');
              }}
            >
              {t('取消')}
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </div>
  );
};

export default addAlertForm;
