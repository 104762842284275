import React from "react";
import {Button, Card, Col, Form, Input, Row, Space, Select, message,InputNumber} from "antd";
import {parseValues} from "@/pages/warning/template/components/utils";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import { addDatasourceDetail, updateDatasource} from "@/services/datasource";

interface Props {
    detail?: any;
    type?: number; // 1:编辑 2:克隆
}

const DatasourceForm: React.FC<Props> = ({ type, detail = {} }) => {

    const [form] = Form.useForm();
    const { t } = useTranslation();
    const history = useHistory();

    const addDatasource = () => {
        form
            .validateFields()
            .then(async (values) => {
                if (type === 1) {
                    const res = await updateDatasource(detail.id,values );
                    if (res.err) {
                        message.error(res.error);
                    } else {
                        message.success(t('编辑成功！'));
                        history.push('/datasource');
                    }
                } else {
                    const res = await addDatasourceDetail(values);
                    if (res.err) {
                        message.error(res.error);
                    } else {
                        message.success(t('编辑成功！'));
                        history.push('/datasource');
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return(
        <div className='operate_con'>
            <Form
                form={form}
                className='strategy-form'
                layout='vertical'
                initialValues={{
                    ...parseValues(detail),
                    cate: detail.cate,
                }}
            >
                <Space direction='vertical' style={{ width: '100%' }}>
                    <Card title={t('基本配置')}>
                        <Row gutter={16}>
                            <Col span={8}>
                                <Form.Item
                                    label={t('数据源名称：')}
                                    name='cluster_name'
                                    rules={[
                                        {
                                            required: true,
                                            message: t('数据源组名称不能为空'),
                                        },
                                    ]}
                                >
                                    <Input placeholder={t('请输入数据源名称')} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={t('数据源链接：')}
                                    name='url'
                                    rules={[
                                        {
                                            required: true,
                                            message: t('数据源链接不能为空'),
                                        },
                                    ]}
                                >
                                    <Input placeholder={t('请输入数据源链接')} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={5}>
                                <Form.Item
                                    label={t('数据源类型：')}
                                    name='type'
                                    rules={[
                                        {
                                            required: true,
                                            message: t('数据源类型不能为空'),
                                        },
                                    ]}
                                >
                                    <Select>
                                        <Select.Option value="prometheus">prometheus</Select.Option>
                                        <Select.Option value="clickhouse">clickhouse</Select.Option>
                                        <Select.Option value="influxdb">influxdb</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item
                                    label={t('DataBase：')}
                                    name='data_base'
                                >
                                    <Input placeholder={t('请输入DataBase')} />
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item
                                    label={t('超时时间(ms)：')}
                                    name='timeout'
                                    rules={[
                                        {
                                            required: true,
                                            message: t('超时时间不能为空'),
                                        },
                                    ]}
                                >
                                    <InputNumber min={1000} placeholder={t('请输入超时时间')} style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={8}>
                                <Form.Item
                                    label={t('数据源用户名：')}
                                    name='basic_auth_user'
                                    rules={[
                                        {
                                            required: true,
                                            message: t('数据源用户名不能为空'),
                                        },
                                    ]}
                                >
                                    <Input placeholder={t('请输入数据源用户名')} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label={t('数据源密码：')}
                                    name='basic_auth_pass'
                                    rules={[
                                        {
                                            required: true,
                                            message: t('数据源密码不能为空'),
                                        },
                                    ]}
                                >
                                    <Input placeholder={t('请输入数据源密码')} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                    <Form.Item
                        style={{
                            marginTop: 20,
                        }}
                    >
                        <Button type='primary'  onClick={addDatasource}  style={{ marginRight: '8px' }}>
                            {type === 1 ? t('编辑') : type === 2 ? t('克隆') : t('创建')}
                        </Button>

                        <Button
                            onClick={() => {
                                history.push('/cluster-group');
                            }}
                        >
                            {t('取消')}
                        </Button>
                    </Form.Item>
                </Space>
            </Form>
        </div>
    )
}

export default DatasourceForm;
