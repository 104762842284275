/*
 * @Descripttion:
 * @Version:
 * @Author: Wang Xiaoguo
 * @Date: 2023-02-15 15:44:55
 * @LastEditors: Wang Xiaoguo
 * @LastEditTime: 2023-02-15 18:40:08
 */
import React, { useState } from 'react';
import { Row, Col, Button } from 'antd';
import _ from 'lodash';
import PromQueryBuilderModal from '@/components/PromQueryBuilder/PromQueryBuilderModal';
import PromQLInput, { CMExpressionInputProps } from './index';
import Graph from './PromGraph/Graph';
import { QueryStats } from './PromGraph/components/QueryStatsView';
import { IRawTimeRange } from '@/components/TimeRangePicker';

const ButtonGroup = Button.Group;

export function PromQLInputWithBuilder(props: CMExpressionInputProps & { cluster: string }) {
  const [refreshFlag, setRefreshFlag] = useState(_.uniqueId('refreshFlag_'));
  const [promql, setPromql] = useState<string | undefined>('');
  const [queryStats, setQueryStats] = useState<QueryStats | null>(null);
  const [errorContent, setErrorContent] = useState('');
  const [range, setRange] = useState<IRawTimeRange>({ start: 'now-1h', end: 'now' });
  const onTimeChange = (newRange) => { };
  const [step, setStep] = useState<number>();

  return (
    <div>
      <Row gutter={8}>
        <Col flex='auto'>
          <PromQLInput {..._.omit(props, 'cluster')} />
        </Col>
        <Col flex='74px'>
          <ButtonGroup>
            <Button
              onClick={() => {
                PromQueryBuilderModal({
                  // TODO: PromQL 默认是最近12小时，这块应该从使用组件的环境获取实际的时间范围
                  range: {
                    start: 'now-12h',
                    end: 'now',
                  },
                  datasourceValue: props.cluster,
                  value: props.value,
                  onChange: (val) => {
                    props.onChange && props.onChange(val);
                  },
                });
              }}
            >
              新手模式
            </Button>
            <Button
              type='primary'
              onClick={() => {
                setRefreshFlag(_.uniqueId('refreshFlag_'));
                setPromql(props.value);
              }}
            >
              指标查询
            </Button>
          </ButtonGroup>

        </Col>
      </Row>
      {
        promql != "" && promql != undefined ? <Graph
          // datasourceId={1024}
          type={"prom"}
          datasourceName={props.cluster}
          promql={promql}
          setQueryStats={setQueryStats}
          setErrorContent={setErrorContent}
          range={range}
          setRange={(newRange) => {
            setRange(newRange);
            onTimeChange && onTimeChange(newRange);
          }}
          step={step}
          setStep={setStep}
          refreshFlag={refreshFlag}
          url='/api/n9e/prometheus'
          contentMaxHeight={300}
          datasourceIdRequired={false}
          graphOperates={{ enabled: true }}
        /> : ""
      }
    </div>
  );
}


export function ClickhouseQLInputWithBuilder(props: CMExpressionInputProps & { cluster: string }) {
  const [refreshFlag, setRefreshFlag] = useState(_.uniqueId('refreshFlag_'));
  const [promql, setPromql] = useState<string | undefined>('');
  const [queryStats, setQueryStats] = useState<QueryStats | null>(null);
  const [errorContent, setErrorContent] = useState('');
  const [range, setRange] = useState<IRawTimeRange>({ start: 'now-1h', end: 'now' });
  const onTimeChange = (newRange) => { };
  const [step, setStep] = useState<number>();

  return (
      <div>
        <Row gutter={8}>
          <Col flex='auto'>
            <PromQLInput {..._.omit(props, 'cluster')} />
          </Col>
          <Col flex='74px'>
            <ButtonGroup>
              <Button
                  type='primary'
                  onClick={() => {
                    setRefreshFlag(_.uniqueId('refreshFlag_'));
                    setPromql(props.value);
                  }}
              >
                指标查询
              </Button>
            </ButtonGroup>

          </Col>
        </Row>
        {/*图表信息获取及展示*/}
        {
          promql != "" && promql != undefined ? <Graph
              // datasourceId={1024}
              type={"click"}
              datasourceName={props.cluster}
              promql={promql}
              setQueryStats={setQueryStats}
              setErrorContent={setErrorContent}
              range={range}
              setRange={(newRange) => {
                setRange(newRange);
                onTimeChange && onTimeChange(newRange);
              }}
              step={step}
              setStep={setStep}
              refreshFlag={refreshFlag}
              url='/api/n9e/clickhouse'
              contentMaxHeight={300}
              datasourceIdRequired={false}
              graphOperates={{ enabled: true }}
          /> : ""
        }
      </div>
  );
}


export function InfluxDBQLInputWithBuilder(props: CMExpressionInputProps & { cluster: string }) {
  const [refreshFlag, setRefreshFlag] = useState(_.uniqueId('refreshFlag_'));
  const [promql, setPromql] = useState<string | undefined>('');
  const [queryStats, setQueryStats] = useState<QueryStats | null>(null);
  const [errorContent, setErrorContent] = useState('');
  const [range, setRange] = useState<IRawTimeRange>({ start: 'now-1h', end: 'now' });
  const onTimeChange = (newRange) => { };
  const [step, setStep] = useState<number>();

  return (
      <div>
        <Row gutter={8}>
          <Col flex='auto'>
            <PromQLInput {..._.omit(props, 'cluster')} />
          </Col>
          <Col flex='74px'>
            <ButtonGroup>
              <Button
                  type='primary'
                  onClick={() => {
                    setRefreshFlag(_.uniqueId('refreshFlag_'));
                    setPromql(props.value);
                  }}
              >
                指标查询
              </Button>
            </ButtonGroup>

          </Col>
        </Row>
        {/*图表信息获取及展示*/}
        {
          promql != "" && promql != undefined ? <Graph
              // datasourceId={1024}
              type={"influxdb"}
              datasourceName={props.cluster}
              promql={promql}
              setQueryStats={setQueryStats}
              setErrorContent={setErrorContent}
              range={range}
              setRange={(newRange) => {
                setRange(newRange);
                onTimeChange && onTimeChange(newRange);
              }}
              step={step}
              setStep={setStep}
              refreshFlag={refreshFlag}
              url='/api/n9e/influxDB'
              contentMaxHeight={300}
              datasourceIdRequired={false}
              graphOperates={{ enabled: true }}
          /> : ""
        }
      </div>
  );
}


export function VmGroupInputWithBuilder(props: CMExpressionInputProps & { cluster: string }) {
    const [refreshFlag, setRefreshFlag] = useState(_.uniqueId('refreshFlag_'));
    const [promql, setPromql] = useState<string | undefined>('');
    const [queryStats, setQueryStats] = useState<QueryStats | null>(null);
    const [errorContent, setErrorContent] = useState('');
    const [range, setRange] = useState<IRawTimeRange>({ start: 'now-1h', end: 'now' });
    const onTimeChange = (newRange) => { };
    const [step, setStep] = useState<number>();

    return (
        <div>
            <Row gutter={8}>
                <Col flex='auto'>
                    <PromQLInput {..._.omit(props, 'cluster')} />
                </Col>
                <Col flex='74px'>
                    <ButtonGroup>
                        <Button
                            type='primary'
                            onClick={() => {
                                setRefreshFlag(_.uniqueId('refreshFlag_'));
                                setPromql(props.value);
                            }}
                        >
                            指标查询
                        </Button>
                    </ButtonGroup>

                </Col>
            </Row>
            {/*图表信息获取及展示*/}
            {
                promql != "" && promql != undefined ? <Graph
                    // datasourceId={1024}
                    type={"vm_group"}
                    datasourceName={props.cluster}
                    promql={promql}
                    setQueryStats={setQueryStats}
                    setErrorContent={setErrorContent}
                    range={range}
                    setRange={(newRange) => {
                        setRange(newRange);
                        onTimeChange && onTimeChange(newRange);
                    }}
                    step={step}
                    setStep={setStep}
                    refreshFlag={refreshFlag}
                    url='/api/n9e/vmGroup'
                    contentMaxHeight={300}
                    datasourceIdRequired={false}
                    graphOperates={{ enabled: true }}
                /> : ""
            }
        </div>
    );
}
