import request from '@/utils/request';
import { RequestMethod } from '@/store/common';

// 获取所有角色
export const getRole = function (params) {
  return request(`/api/n9e/roles`, {
    method: RequestMethod.Get,
    params,
  });
};

// 创建角色
export const addRole = function (data: object) {
  return request(`/api/n9e/role`, {
    method: RequestMethod.Post,
    data,
  });
};

// 修改角色
export const updateRole = function (id: string, data: object) {
  return request(`/api/n9e/role/${id}`, {
    method: RequestMethod.Put,
    data,
  });
};

export const deleteRole = function (id: string) {
  return request(`/api/n9e/role/${id}`, {
    method: RequestMethod.Delete,
  });
};

export const getRoleInfo = function (id: string) {
  return request(`/api/n9e/role/${id}`, {
    method: RequestMethod.Get,
  }).then((res) => res && res.dat);
};
