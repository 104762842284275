import React, { useMemo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PageLayout from '@/components/pageLayout';
import OperateForm from './components/operateForm';
import { useTranslation } from 'react-i18next';
import { getAlertTemplate } from '@/services/warning';
import { useQuery } from '@/utils';

import './index.less';

const AlertTemplateEdit: React.FC = () => {
  const { t } = useTranslation();
  const query = useQuery();
  const isClone = query.get('mode');
  const params: any = useParams();
  const id = useMemo(() => {
    return params.id;
  }, [params]);
  const [curAlertTemplate, setCurAlertTemplate] = useState<any>({});
  useEffect(() => {
    getAlertTemplateDetail();
    return () => {};
  }, [id]);

  const getAlertTemplateDetail = async () => {
    const res = await getAlertTemplate(id);
    setCurAlertTemplate(res.dat || {});
  };

  return (
    <PageLayout title={t('告警模板')} showBack backPath='/alert-template' hideCluster>
      {curAlertTemplate.id && <OperateForm detail={curAlertTemplate} type={!isClone ? 1 : 2} />}
    </PageLayout>
  );
};

export default AlertTemplateEdit;
