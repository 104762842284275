import React from "react";
import {useTranslation} from "react-i18next";
import PageLayout from "@/components/pageLayout";
import ClusterGroupList from "@/pages/warning/clusterGroup/components/clusterGroupTable";

const ClusterGroupListTable: React.FC = () => {
    const { t } = useTranslation();
    return (
        <PageLayout title={t('数据源组管理')} hideCluster>
            <div className='strategy-content'>
                <ClusterGroupList />
            </div>
        </PageLayout>
    );
}

export default ClusterGroupListTable;
