import React from 'react';
import PageLayout from '@/components/pageLayout';
import PlatformOperateForm from './components/platformOperateForm';
import { useTranslation } from 'react-i18next';

import './index.less';

const AlertTemplatePlatformAdd: React.FC = () => {
  const { t } = useTranslation();
  return (
    <PageLayout title={t('告警模板归属平台')} showBack hideCluster>
      <PlatformOperateForm />
    </PageLayout>
  );
};

export default AlertTemplatePlatformAdd;
