import request from "@/utils/request";
import {RequestMethod} from "@/store/common";

export const getClusterGroupList = function (params) {
    return request('/api/n9e/cluster-group',{
        method:RequestMethod.Get,
        params
    })
}

export const delClusterGroup = function (id){
    return request('/api/n9e/cluster-group/'+id,{
        method:RequestMethod.Delete,
    })
}

export const updateClusterGroup = function (id,data){
    return request('/api/n9e/cluster-group/'+id,{
        method:RequestMethod.Put,
        data
    })
}

export const getClusterGroupById = function (id){
    return request('/api/n9e/cluster-group/'+id,{
        method:RequestMethod.Get,
    })
}

export const addClusterGroup = function (data){
    return request('/api/n9e/cluster-group',{
        method:RequestMethod.Post,
        data
    })
}
